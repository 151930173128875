@use "sass:meta" as ---fs4nvhie6me;.main-v2-market {
  @include breakpoint(medium down) {
    display: none;
  }

  margin-top: 2rem;

  .wrapper {
    display: flex;
    align-items: center;
    background-color: $alice-blue;
    justify-content: space-between;

    .rubrics {
      background-color: $tangerine-yellow;
      padding: 26px 20px;
      width: 210px;
      flex-shrink: 0;
    }

    a {
      color: $black;
    }

    .item {
      display: flex;
      align-items: center;
      margin-left: 20px;
      width: 260px;

      img {
        width: 60px;
      }

      &_title {
        margin-left: 1rem;
        line-height: 1.2;
        font-weight: 500;
      }
    }
  }
}

;@include ---fs4nvhie6me.load-css("sass-embedded-legacy-load-done:3767");